import React, { useState, useEffect } from "react";
import debounce from "lodash/debounce";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { CiEdit, CiTrash } from "react-icons/ci";

export default function TableExample({ clientes, deleteCliente, updateCliente }) {
  console.log(clientes); // Ensure it's an array and not empty or undefined
  const [editingClientId, setEditingClientId] = useState(null);
  const [editedData, setEditedData] = useState({});

  // Handle the change for form inputs 
  //the famous gh daily push :), again hehee
  // Oh shit, here we go again :D
  // Making the famous daily push ;)
  // We keeping that strikeee
  // LetS keep pushing
  // We are on a roll
  // Even on the most complicated days... gh push
  // Let's start my MONDAYYYY
  // Let's gooo
  // GH daily push
  // Let's finish the week stronggg
  // :)
  // Even on weekends

  //gh daily
  //gh tuesdaily
  // daily again
  // daily :D
  // daily push

  // we are not loosing it on weekends nomore
  //let's keep it going
  //another one
  // and another one
  // there we go
  //anotha one
  //dailyyy
  // push
  // push push pushhh//

  //omg got my first full time programming job at an established company
  //omg day two of this while working
  //lets goo
  //anotha one
  // daily
  //let's see if now it is working...
  // hi
  //anotha one!
  //daily
  // amaznigg
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Function to start editing
  const startEditing = (cliente) => {
    setEditingClientId(cliente.numerodecausa);  // Track the client being edited
    setEditedData({ ...cliente });  // Make sure you're spreading the cliente to include all fields
  };



  // Function to stop editing and save changes
  const saveChanges = () => {
    console.log("Edited Data:", editedData); // Log the data being sent
    if (editedData) {
      updateCliente(editedData);  // Call the function passed from the parent
      setEditingClientId(null);  // Exit edit mode
      setEditedData({});  // Clear the form data
    } else {
      console.error("Missing required data for update");
    }
  };


  return (
    <div className="outside-table-div">
      <div className="table-div">
        <Table>
          <Thead>
            <Tr className="tabla-rows">
              <Th>Acciones</Th>
              <Th>Cliente</Th>
              <Th>Contraparte Nombre</Th>
              <Th>Carátula</Th>
              <Th>Juzgado</Th>
              <Th>Tiene mev?</Th>
              <Th>Set de busqueda?</Th>
              <Th>Tiene convenio?</Th>
              <Th>Ius Previsional</Th>
              <Th>Bono Ley</Th>
              <Th>Taza</Th>
              <Th>Sobre Taza</Th>
              <Th>Causa de</Th>
              <Th>Descripción</Th>
              <Th>Cliente DNI</Th>
              <Th>Cliente Domicilio</Th>
              <Th>Cliente Teléfono</Th>
              <Th>Cliente Email</Th>
              <Th>Nro de causa</Th>
              <Th>Contraparte DNI</Th>
              <Th>Contraparte Domicilio</Th>
              <Th>Contraparte Teléfono</Th>
              <Th>Contraparte Email</Th>
            </Tr>
          </Thead>
          <Tbody className="tbody-rows">
            {Array.isArray(clientes) && clientes.length > 0 ? (
              clientes.map((cliente, index) => (
                <Tr key={index}>
                  <Td>
                    {editingClientId === cliente.numerodecausa ? (
                      <>
                        <button className="actions-db" onClick={saveChanges}>
                          Save
                        </button>
                        <button
                          className="actions-db"
                          onClick={() => {
                            setEditingClientId(null);
                            setEditedData({});  // Clear edited data on cancel
                          }}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className="actions-db"
                          onClick={() => startEditing(cliente)}
                        >
                          <CiEdit />
                        </button>
                        <button
                          className="actions-db"
                          onClick={() =>
                            deleteCliente(cliente.numerodecausa)
                          }
                        >
                          <CiTrash />
                        </button>
                      </>
                    )}
                  </Td>

                  {editingClientId === cliente.numerodecausa ? (
                    <>
                      <Td>
                        <input
                          type="text"
                          name="demandantenombre"
                          value={editedData.demandantenombre || ""}
                          onChange={handleChange}
                        />
                      </Td>
                      <Td>
                        <input
                          type="text"
                          name="demandadonombre"
                          value={editedData.demandadonombre || ""}
                          onChange={handleChange}
                        />
                      </Td>
                      <Td>
                        <input
                          type="text"
                          name="demandantecaratula"
                          value={editedData.demandantecaratula || ""}
                          onChange={handleChange}
                        />
                      </Td>
                      <Td>
                        <input
                          type="text"
                          name="demandadojuzgado"
                          value={editedData.demandadojuzgado || ""}
                          onChange={handleChange}
                        />
                      </Td>
                      <Td>
                        <input
                          type="checkbox"
                          name="tieneMev"
                          checked={editedData.tieneMev || false}
                          onChange={() =>
                            setEditedData({
                              ...editedData,
                              tieneMev: !editedData.tieneMev,
                            })
                          }
                        />
                      </Td>
                      <Td>
                        <input
                          type="checkbox"
                          name="setdebusqueda"
                          checked={editedData.setdebusqueda || false}
                          onChange={() =>
                            setEditedData({
                              ...editedData,
                              setdebusqueda: !editedData.setdebusqueda,
                            })
                          }
                        />
                      </Td>
                      <Td>
                        <input
                          type="checkbox"
                          name="tieneConvenio"
                          checked={editedData.tieneConvenio || false}
                          onChange={() =>
                            setEditedData({
                              ...editedData,
                              tieneConvenio: !editedData.tieneConvenio,
                            })
                          }
                        />
                      </Td>
                      <Td>
                        <input
                          type="checkbox"
                          name="iusprevisional"
                          checked={editedData.iusprevisional || false}
                          onChange={() =>
                            setEditedData({
                              ...editedData,
                              iusprevisional: !editedData.iusprevisional,
                            })
                          }
                        />
                      </Td>
                      <Td>
                        <input
                          type="checkbox"
                          name="bonoley"
                          checked={editedData.bonoley || false}
                          onChange={() =>
                            setEditedData({
                              ...editedData,
                              bonoley: !editedData.bonoley,
                            })
                          }
                        />
                      </Td>
                      <Td>
                        <input
                          type="checkbox"
                          name="taza"
                          checked={editedData.taza || false}
                          onChange={() =>
                            setEditedData({
                              ...editedData,
                              taza: !editedData.taza,
                            })
                          }
                        />
                      </Td>
                      <Td>
                        <input
                          type="checkbox"
                          name="sobretaza"
                          checked={editedData.sobretaza || false}
                          onChange={() =>
                            setEditedData({
                              ...editedData,
                              sobretaza: !editedData.sobretaza,
                            })
                          }
                        />
                      </Td>
                      <Td>
                        <input
                          type="text"
                          name="demandantedni"
                          value={editedData.demandantedni || ""}
                          onChange={handleChange}
                        />
                      </Td>
                      <Td>
                        <input
                          type="text"
                          name="demandantedomicilio"
                          value={editedData.demandantedomicilio || ""}
                          onChange={handleChange}
                        />
                      </Td>
                      <Td>
                        <input
                          type="text"
                          name="demandantetelefono"
                          value={editedData.demandantetelefono || ""}
                          onChange={handleChange}
                        />
                      </Td>
                      <Td>
                        <input
                          type="text"
                          name="demandanteemail"
                          value={editedData.demandanteemail || ""}
                          onChange={handleChange}
                        />
                      </Td>
                      <Td>
                        {cliente.numerodecausa}
                      </Td>

                      <Td>
                        <input
                          type="text"
                          name="demandadodni"
                          value={editedData.demandadodni || ""}
                          onChange={handleChange}
                        />
                      </Td>
                      <Td>
                        <input
                          type="text"
                          name="demandadodomicilio"
                          value={editedData.demandadodomicilio || ""}
                          onChange={handleChange}
                        />
                      </Td>
                      <Td>
                        <input
                          type="text"
                          name="demandadotelefono"
                          value={editedData.demandadotelefono || ""}
                          onChange={handleChange}
                        />
                      </Td>

                      <Td>
                        <input
                          type="text"
                          name="demandadoemail"
                          value={editedData.demandadoemail || ""}
                          onChange={handleChange}
                        />
                      </Td>



                      <Td>
                        <select
                          name="causade"
                          value={editedData.causade || ""}
                          onChange={handleChange}
                        >
                          <option value="">Seleccione dueño de causa</option>
                          <option value="ANA">ANA</option>
                          <option value="JORGE">JORGE</option>
                          <option value="MARIA">MARIA</option>
                          <option value="ALEJANDRA">ALEJANDRA</option>
                          <option value="PASANTES">PASANTES</option>
                        </select>
                      </Td>
                      <Td>
                        <input
                          type="text"
                          name="descripcion"
                          value={editedData.descripcion || ""}
                          onChange={handleChange}
                        />
                      </Td>


                    </>
                  ) : (
                    <>
                      <Td>{cliente.demandantenombre}</Td>
                      <Td>{cliente.demandadonombre}</Td>
                      <Td>{cliente.demandantecaratula}</Td>
                      <Td>{cliente.demandadojuzgado}</Td>
                      <Td>{cliente.tienemev === true ? "Si" : "No"}</Td>
                      <Td>{cliente.setdebusqueda === true ? "Autorizado" : "Con Autorizacion"}</Td>
                      <Td>{cliente.tieneConvenio === true ? "Si" : "No"}</Td>
                      <Td>{cliente.iusprevisional === true ? "Si" : "No"}</Td>
                      <Td>{cliente.bonoley === true ? "Si" : "No"}</Td>
                      <Td>{cliente.taza === true ? "Si" : "No"}</Td>
                      <Td>{cliente.sobretaza === true ? "Si" : "No"}</Td>
                      <Td>{cliente.causade}</Td>
                      <Td>{cliente.descripcion}</Td>
                      <Td>{cliente.demandantedni}</Td>
                      <Td>{cliente.demandantedomicilio}</Td>
                      <Td>{cliente.demandantetelefono}</Td>
                      <Td>{cliente.demandanteemail}</Td>
                      <Td>{cliente.numerodecausa}</Td>
                      <Td>{cliente.demandadodni}</Td>
                      <Td>{cliente.demandadodomicilio}</Td>
                      <Td>{cliente.demandadotelefono}</Td>
                      <Td>{cliente.demandadoemail}</Td>



                    </>
                  )}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan="6">No clients available</Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </div>
    </div>
  );
}
